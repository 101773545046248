<template>
  <Row>
    <Column :width="2">
      <Button v-on:click="details">{{ MC.Menu.Details.value() }}</Button>
    </Column>
    <Column :width="13"/>
  </Row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';

import MC from "@/domain/session/MC.js";
import Button from "@/portals/shared/library/button/Button.vue";

//import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: "schedule-menu-row",
  components: {
    Button,
    Row, Column,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    scheduleId: { type: String, default: "" },
  },
  data() {
    return {
      isLoading: true,
      MC: new MC(),
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
  },
  watch: {
    
  },
  mounted: function () {
  },
  methods: {
    ...mapActions([
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                 ]),

    start: function() {
    },
    details: function() {
      if (!this.scheduleId) {
        return;
      }
      var params = {
          id: this.scheduleId,
        };
        
      this.$router.push({
        name: ConstUtils.ROUTES.SCHEDULE.DETAILS,
        params: params,
      });
    },
  },
}
</script>