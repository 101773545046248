
import RcDomainMap from "../../common/RcDomainMap.js";
import Schedule from './Schedule.js';

import ScheduleUtils      from './ScheduleUtils.js';

export default class ScheduleMap extends RcDomainMap {
  static MODEL_NAME = Schedule.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, ScheduleMap.MODEL_NAME);
  }

  find() {
    var map = new ScheduleMap(this.domain);
    var keys = this.keys();
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var role = this.domain.schedules().findById(key);
      map.add(role);
    }
    return map;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getSchedule(key);
      values.push(item);
    }
    return values;
  }
  
  sort() {
    var list = this.list();
    return list.sort(ScheduleUtils.SortByName);
  }
  
  first() {
    var keys = this.keys();
    var role = new Schedule(this.domain, {});
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      role = this.getSchedule(key);
      break;
    }
    return role;
  }
  
  getSchedule(key) {
    return new Schedule(this.domain, this.get(key));
  }
  
  copy() {
    var data = this._copy();
    return new ScheduleMap(this.domain, data);
  }
}